import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "body-text-regular-14 lh-20 mb-4" }
const _hoisted_2 = { class: "ml-1 font-weight-semi-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_colored_ul = _resolveComponent("tm-colored-ul")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "upgradePlan",
    "modal-title": "Upgrade plan",
    size: "small"
  }, {
    "modal-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.text), 1),
      _createVNode(_component_tm_colored_ul, {
        color: "blue",
        "circle-size": "8px",
        class: "body-text-regular-14 lh-20"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
            return (_openBlock(), _createElementBlock("li", {
              key: i,
              class: _normalizeClass({
            'mt-3': i,
          })
            }, [
              _createTextVNode(_toDisplayString(item.title) + ": ", 1),
              _createElementVNode("span", _hoisted_2, _toDisplayString(item.text), 1)
            ], 2))
          }), 128))
        ]),
        _: 1
      })
    ]),
    "modal-footer-button": _withCtx(() => [
      _createVNode(_component_tm_button, {
        size: "large",
        color: _ctx.btnColor,
        to: _ctx.link
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnText), 1)
        ]),
        _: 1
      }, 8, ["color", "to"])
    ]),
    _: 1
  }))
}