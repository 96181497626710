
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmColoredUl from '@/components/shared/templates/TmColoredUl.vue'
import type { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
    TmColoredUl,
  },
  props: {
    text: {
      type: String,
      default: 'By clicking “Upgrade” I agree that the plan will be upgraded and my primary payment method will be charged immediately.',
    },
    btnText: {
      type: String,
      default: 'Upgrade',
    },
    btnColor: {
      type: String,
      default: 'blue',
    },
    link: {
      type: Object as PropType<RouteLocationRaw>,
      default: () => ({ name: 'base.admin.overview.plan' }),
    },
    items: {
      type: Array,
      default: () => ([
        {
          title: 'Plan',
          text: 'Pro monthly',
        },
        {
          title: 'Price',
          text: '$999 per month',
        },
        {
          title: 'Payment method',
          text: 'Visa - 6344',
        },
      ]),
    },
  },
})
